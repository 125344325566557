<ng-container *ngIf="order">
    <h4 [innerHTML]="translationService.getTranslation('offer_ordered_title')"></h4>
    <div [innerHTML]="translationService.getTranslation('offer_ordered_description')"></div>
    <div *ngIf="!isUserLoggedIn"
         [innerHTML]="translationService.getTranslation('offer_ordered_no_logged_in_user')"></div>
    <div id="orderConfirmation">
        <div id="orderConfirmationText" *ngIf="offer && offer.confirmationText"
             [innerHTML]="offer?.confirmationText"></div>
        <img id="orderConfirmationImage" *ngIf="offer && offer.confirmationImage" [src]="offer.confirmationImage"
             class="bonusImg" alt="" fitImage>
    </div>
    <div class="row">
        <ng-container *ngIf="order.productDescription && order.variantDescription">
            <div class="col-6" [innerHTML]="translationService.getTranslation('order_object') + ' / ' + translationService.getTranslation('order_edition')"></div>
            <div class="col-6">{{order.productDescription}} / {{order.variantDescription}}</div>
        </ng-container>
        <ng-container>
            <div class="col-6" [innerHTML]="translationService.getTranslation('period')"></div>
            <div class="col-6">{{order.validDate.validFrom | date: translationService.getTranslation('dateFormat')}}
                - {{order.validDate.validUntil | date: translationService.getTranslation('dateFormat')}}</div>
        </ng-container>
        <ng-container *ngIf="order.curDelivery">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_delivery')"></div>
            <div
                    class="col-6">{{order.curDelivery.person.salutationDescription}} {{order.curDelivery.person.firstname}} {{order.curDelivery.person.lastname}} {{order.curDelivery.address.street}} {{order.curDelivery.address.country === 'A' ? order.curDelivery.address.houseno + " " + order.curDelivery.address.stock + " " + order.curDelivery.address.stiege + " " + order.curDelivery.address.tuer : order.curDelivery.address.houseno}} {{order.curDelivery.address.zipcode}} {{order.curDelivery.address.city}}</div>
        </ng-container>
        <ng-container *ngIf="order.curBilling">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_billing')"></div>
            <div
                    class="col-6">{{order.curBilling.person.salutationDescription}} {{order.curBilling.person.firstname}} {{order.curBilling.person.lastname}} {{order.curBilling.address.street}} {{order.curBilling.address.country === 'A' ? order.curBilling.address.houseno + " " + order.curBilling.address.stock + " " + order.curBilling.address.stiege + " " + order.curBilling.address.tuer : order.curBilling.address.houseno}} {{order.curBilling.address.zipcode}} {{order.curBilling.address.city}}</div>
        </ng-container>
        <ng-container *ngIf="order.curPayment">
            <div class="col-6" [innerHTML]="translationService.getTranslation('payment_type')"></div>
            <div class="col-6">{{order.curPayment?.paymentType?.payMentMethodDescription }}
                / {{order.curPayment.paymentType.paymentFrequencyDescription}} {{order.curPayment.bank?.swiftIBAN}}</div>
        </ng-container>
    </div>

    <ng-container *ngIf="timeUntilRedirectSeconds > 0 && redirectUrl?.length > 0">
        <div class="mt-3">
            Sie werden in {{timeUntilRedirectSeconds}} Sekunden weiter geleitet.
        </div>
    </ng-container>

    <ng-container>
        <div class="mt-3">
            <button mat-button
                    (click)="routeToOrderList()" [innerHTML]="translationService.getTranslation('button_go_to_subscription_overview')"></button>
        </div>
    </ng-container>

</ng-container>
